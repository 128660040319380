<template>
  <div class="review border m-auto s-layout" :style="colorObject">
    <div class="s-container">
      <Header :page-title="pageTitle" :header-section="headerSection" />

      <Tabs :tabs="tabs" currentTab="apply" />
      <div class="content">
        <coupon-filters
          class="s-mb-3"
          :options="filterOptions"
          v-model="filter"
        ></coupon-filters>
        <coupon-card
          v-for="item in coupons"
          :key="item.id"
          :coupon="item"
          buttonText="查看"
        >
        </coupon-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Page/Liff/Shared/HeaderV2";
import Tabs from "@/components/Page/Liff/Shared/TabsV2";
import CouponCard from "@/pages/Dashboard/Coupon/CouponCard.vue";
import CouponFilters from "@/pages/Dashboard/Coupon/CouponFilters.vue";

const coupons = [
  {
    merchant_id: 3,
    event_id: null,
    redeem_code_bundle_id: 37,
    branch_id: null,
    code: "ADOW150",
    start_at: "2023-08-23T00:00:00+0800",
    end_at: "2050-12-09T23:59:59+0800",
    belong_at: "2023-09-08T14:38:16+0800",
    apply_at: "2023-09-08T14:38:16+0800",
    redeem_at: null,
    is_enabled: true,
    created_at: "2023-09-08T14:38:16+0800",
    updated_at: "2023-09-08T14:38:16+0800",
    name: "LINE POINT-1114",
  },
  {
    merchant_id: 3,
    event_id: null,
    redeem_code_bundle_id: 37,
    branch_id: null,
    code: "ADOW150",
    start_at: "2023-08-23T00:00:00+0800",
    end_at: "2050-12-09T23:59:59+0800",
    belong_at: "2023-09-08T14:38:16+0800",
    apply_at: "2023-09-08T14:38:16+0800",
    redeem_at: null,
    is_enabled: true,
    created_at: "2023-09-08T14:38:16+0800",
    updated_at: "2023-09-08T14:38:16+0800",
    name: "LINE POINT-1114",
  },
  {
    merchant_id: 3,
    event_id: null,
    redeem_code_bundle_id: 37,
    branch_id: null,
    code: "ADOW150",
    start_at: "2023-08-23T00:00:00+0800",
    end_at: "2050-12-09T23:59:59+0800",
    belong_at: "2023-09-08T14:38:16+0800",
    apply_at: "2023-09-08T14:38:16+0800",
    redeem_at: null,
    is_enabled: true,
    created_at: "2023-09-08T14:38:16+0800",
    updated_at: "2023-09-08T14:38:16+0800",
    name: "LINE POINT-1114",
  },
];

export default {
  components: {
    Header,
    Tabs,
    CouponCard,
    CouponFilters,
  },
  props: {
    pageTitle: {
      type: String,
      default: null,
    },
    headerSection: {
      type: Object,
      default: null,
    },
    memberCardSection: {
      type: Object,
      default: null,
    },
    tabs: {
      type: Array,
    },
    colorObject: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeTab: "LiffCouponHome",
      coupons,
      filter:'all'
    };
  },
  computed: {
    ...mapGetters({
      themeConfig: "liffGeneral/themeConfig",
    }),
    filterOptions() {
      return [
        {
          key: "all",
          text: `${"全部 (10)"}`,
        },
        {
          key: "upcoming",
          text: `${"即將到期 (10)"}`,
        },
        {
          key: "notYet",
          text: `${"尚未開始 (10)"}`,
        },
      ];
    },
  },

  async mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";
@import "@/pages/Liff/MemberCenter/style.scss";

.review {
  max-width: 390px;
}

.s-layout {
  background-color: var(--liff-layout_bg_color);
  pointer-events: none;
}

.content {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
}
</style>
